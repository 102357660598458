// import Cookie from 'js-cookie'
import { Api } from "./Base";
// import { Api2 } from "./Base2";

// 非權限API
export const getMarkets = () => Api.get("/api/v2/markets.json"); //交易对
export const postLogin = (data) => Api.post("/web/session.json", data); //登入
export const postIdentities = (data) => Api.post("/api/identities.json", data); //註冊
export const getLogout = () => Api.delete("/web/session.json"); //登出
export const postMembers = (data) => Api.post("/web/members.json", data); //郵箱註冊
export const getKline = (data) => Api.get(`/api/v2/k.json?${data}`); //K線
export const postResetEmail = (data) =>
  Api.post("/web/reset_passwords.json", data); //發送信箱重製密碼
export const getPredictions = (data) =>
  Api.get(`/web/predictions.json?${data}`); //取得訂單紀錄
export const getdepthMarket = (name) =>
  Api.get(`/api/v2/depth.json?market=${name}`); //市場深度
export const getTradeList = (data) => Api.get(`/api/v2/trades?${data}`); //歷史成交列表
export const postTradeList = (data, currency) =>
  Api.post(`/web/accounts/${currency}/withdraws`, data); //提款
export const getWithdrawsList = (currency, page) =>
  Api.get(`/web/accounts/${currency}/withdraws.json?page=${page}&count=10`); //提现-记录列表

export const postVerify = (data) =>
  Api.post("/web/members/send_email.json", data); //註冊發送驗證碼

export const postResetPassword = (token, data) =>
  Api.put(`/reset_passwords/${token}.json`, data); //發送重製密碼

//權限API
export const getAccounts = () => Api.get("/web/accounts.json"); //獲取額度訊息
export const getUser = () => Api.get("/web/settings.json"); //獲取用戶訊息

export const postPredictions = (data) =>
  Api.post("/web/predictions.json", data); //下注買漲買跌

export const spotTrading = (data, coin, type) =>
  Api.post(`/markets/${coin}/${type}.json`, data); //現貨交易

export const gettVerify = (currency = "USDT@ETH") =>
  Api.get(`/web/accounts/${currency}/address`); //獲取充值地址
export const getRecharge = (currency, page) =>
  Api.get(`/web/accounts/${currency}/deposits.json?page=${page}&count=10`); //充值时
export const getRechargeList = () =>
  Api.get(`/web/orders.json?page=1&count=10`); //充值订单列表

export const identityVerifyImage = (member_id, data) =>
  Api.post(`/web/id_documents/${member_id}/upload`, data); //實名驗證上傳圖片
export const identityVerify = (member_id, data) =>
  Api.put(`/web/id_documents/${member_id}.json`, data); //實名認證資料
export const getIdentityVerifyData = (member_id) =>
  Api.get(`/web/id_documents/${member_id}.json`); // 取得實名認證資料
export const getCurrentOrderList = (data) => Api.get(`/web/orders?${data}`); //取得委託中訂單資料
export const cancelOrder = (id, marketId) =>
  Api.delete(`/web/markets/${marketId}/orders/${id}`); //取消特定筆掛單資料

//二次驗證相關
export const sendMessage = (data) =>
  Api.post(`/web/two_factors/send_message.json`, data); //发送短信验证码
export const twoFactors = () => Api.get(`/web/two_factors.json`); //查詢二次驗證綁定狀態
export const twoFactorSettings = () =>
  Api.get(`/web/two_factor_settings.json?locale=zh-CN`); //查詢二次驗證設置
export const batchUpdate = (data) =>
  Api.post(`/web/two_factor_settings/batch_update.json`, data); //修改二次驗證設置
