<template>
  <div class="withdrawView">
    <div class="info_box">
      <p class="select_box" v-if="this.$route.params.walletName == 'USDT'">
        {{ $t("charge.usdtType2") }}:
        <select class="select" v-model="selectCode">
          <option label="USDT@ETH" value="USDT@ETH">USDT@ETH</option>
          <option label="USDT@TRX" value="USDT@TRX">USDT@TRX</option>
        </select>
      </p>
      <p>
        {{ $t("withdraw.enterWalletAddressAndPrice1") }}
        {{ this.$route.params.walletName }}
        {{ $t("withdraw.enterWalletAddressAndPrice2") }}
        <br />
        {{ $t("withdraw.contactForServiceAfterSend") }}
      </p>
    </div>

    <div class="new_input_box" v-if="0">
      <div class="title">{{ $t("郵箱驗證碼") }}</div>
      <input class="input input_0" type="text" :placeholder="$t('請輸入郵箱驗證碼')" v-model="otp" />
      <div class="text btn" @click="sendMessageFun()">發送</div>
    </div>
    <div class="msg"></div>

    <div class="new_input_box">
      <div class="title">{{ $t("withdraw.coinWalletAddress") }}</div>
      <input class="input input_0" type="text" :placeholder="$t('withdraw.enterWalletAddress')" v-model="address" />
      <div class="text"></div>
    </div>
    <div class="msg"></div>

    <div class="new_input_box">
      <div class="title">{{ $t("withdraw.withdrawPrice") }}</div>
      <input class="input input_0" :class="[{ right: amount != '' }]" type="number" :placeholder="$t('withdraw.enterWithdrawPrice1') +
        ' ' +
        this.$route.params.walletName +
        ' ' +
        $t('withdraw.enterWithdrawPrice2')
        " v-model="amount" min="0" />
      <div class="text">{{ this.$route.params.walletName }}</div>
    </div>
    <div class="msg"></div>

    <div class="new_input_box">
      <div class="title">{{ $t("withdraw.fee") }}</div>
      <div class="value">{{ showFee }}</div>
      <div class="text">{{ this.$route.params.walletName }}</div>
    </div>
    <div class="msg"></div>

    <div class="new_input_box" :class="[
      { red_border_bottom: sumAmount },
    ]">
      <div class="title">{{ $t("withdraw.total") }}</div>
      <div class="value">
        {{ sumAmount }}
      </div>
      <div class="text">{{ this.$route.params.walletName }}</div>
    </div>
    <div class="msg">
      {{
        insufficientBalance
          ? $t("kline.insufficientBalance")
          : null
      }}
    </div>

    <div class="new_input_box">
      <div class="title title2">{{ $t("withdraw.actualCredit") }}</div>
      <div class="value">{{ amount && amount > 0 ? amount : null }}</div>
      <div class="text">{{ this.$route.params.walletName }}</div>
    </div>
    <div class="msg"></div>

    <div class="input_content">
      <!-- <div class="input_box">
        <div>{{ $t("withdraw.coinWalletAddress") }}</div>
        <input
          class="input"
          type="text"
          :placeholder="$t('withdraw.enterWalletAddress')"
          v-model="address"
        />
      </div>

      <div class="input_box">
        <div>
          {{ $t("withdraw.withdrawPrice1") }}
          {{ this.$route.params.walletName }}
          {{ $t("withdraw.withdrawPrice2") }}
        </div>
        <input
          class="input"
          type="text"
          :placeholder="
            $t('withdraw.enterWithdrawPrice1') +
            ' ' +
            this.$route.params.walletName +
            ' ' +
            $t('withdraw.enterWithdrawPrice2')
          "
          v-model="amount"
        />
      </div> -->

      <button class="btn" @click="handleClick">
        {{ $t("withdraw.send") }}
      </button>
    </div>

    <el-divider></el-divider>

    <div class="title">{{ $t("charge.withdrawRecord") }}</div>

    <div class="content">
      <div class="no_data" v-if="withdrawsList.length == 0">
        {{ $t("charge.noData") }}
      </div>
      <div class="list" v-for="(item, index) in withdrawsList" :key="index">
        <div class="row">
          <span>sn</span>
          <span>{{ item.sn }}</span>
        </div>
        <div class="row">
          <span>{{ $t("charge.withdraw_fund_uid") }}</span>
          <span class="break_all">{{ item.fund_uid }}</span>
        </div>
        <div class="row">
          <span>{{ $t("charge.amount") }}</span>
          <span>{{ item.amount }}</span>
        </div>
        <div class="row">
          <span>{{ $t("charge.fee") }}</span>
          <span>{{ item.fee }}</span>
        </div>
        <div class="row">
          <span>{{ $t("charge.created_at") }}</span>
          <span>{{ handleTime(item.created_at) }}</span>
        </div>
        <div class="row">
          <span>{{ $t("charge.updated_at") }}</span>
          <span>{{ handleTime(item.updated_at) }}</span>
        </div>
        <div class="row">
          <span>{{ $t("charge.state") }}</span>
          <span>{{ stateFun(item.state) }}</span>
        </div>
        <div class="row">
          <span>{{ $t("charge.cancel_reason") }}</span>
          <span class="break_word">{{ item.cancel_reason }}</span>
        </div>
      </div>

      <el-pagination layout="prev, pager, next" :page-count="RecordData.total_pages" @current-change="changePage"
        :pager-count="5" :hide-on-single-page="true">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  postTradeList,
  getWithdrawsList,
  getAccounts,
  sendMessage,
  twoFactors,
  // twoFactorSettings,
} from "@/services/Api.js";
import moment from "moment";
import { Decimal } from 'decimal.js';
export default {
  inject: ["message"],
  name: "WithdrawComponent",

  props: {
    balance: { Number },
  },

  computed: {
    sumAmount() {
      return this.amount && new Decimal(this.amount).gt(0) ? this.fee.plus(this.amount) : null
    },
    insufficientBalance() {
      if (this.amount && this.balance) {
        return this.sumAmount.gt(this.balance)
      } else {
        return null;
      }
    },
    showFee() {
      return this.amount && new Decimal(this.amount).gt(0) ? this.fee : null
    },
    showAmount() {
      return this.amount && new Decimal(this.amount).gt(0) ? this.amount : null
    }
  },

  watch: {
    // selectCode(value) {
    //   this.fee = value == "USDT@ETH" ? 24 : 1;
    //   // this.gettVerifyFun();
    // },
    amount(value) {
      value = new Decimal(value);
      if (value.lt(0)) {
        this.amount = value.abs();
      }
    },
  },

  data() {
    return {
      address: "",
      amount: "",
      withdrawsList: [],
      RecordData: {},
      currentPage: 1,
      selectCode: "USDT@ETH",
      fee: new Decimal(0),
      otp: "",
    };
  },

  mounted() {
    this.getWithdrawsListFun();
    this.getFee();
    this.twoFactorsFun();
    // this.twoFactorSettingsFun();
  },

  methods: {
    handleClick() {
      if (this.address == "" || this.amount == "") {
        this.message("withdraw.inputIncorrect", "error");
        return;
      }

      if (this.fee.plus(this.amount).gt(this.balance)) {
        this.message("kline.insufficientBalance", "error");
        return;
      }

      let locale = window.localStorage.getItem("locale");
      locale = locale == "zht" ? "zh-TW" : locale == "zhs" ? "zh-CN" : "en";

      const postdata = {
        address: this.address,
        amount: this.amount,
        locale: locale,
        two_factor: { type: "email", otp: this.otp },
      };

      const currency =
        this.$route.params.walletName == "USDT"
          ? this.selectCode
          : this.$route.params.walletName;

      postTradeList(postdata, currency.toLowerCase())
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.$alert("", this.$t("withdraw.contactForService"), {
              confirmButtonText: "OK",
              type: "alert",
              customClass: "alert",
            });
            this.address = "";
            this.amount = "";
            setTimeout(() => {
              this.getWithdrawsListFun();
            }, 500);
          } else {
            if (
              res.data.errors[0].message == "Fund uidformat invalid" ||
              res.data.errors[0].message == "Fund uid format invalid"
            ) {
              this.message("charge.error_msg", "error");
            } else {
              this.message(res.data.errors[0].message, "error");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getWithdrawsListFun() {
      getWithdrawsList(
        this.$route.params.walletName.toLowerCase(),
        this.currentPage
      )
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.withdrawsList = res.data.data.withdraws;
            this.RecordData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleTime(time) {
      return moment(time).format("YYYY-MM-DD HH:mm:ss");
    },

    stateFun(state) {
      switch (state) {
        case "submitting":
          return this.$t("charge.submitting");

        case "submitted":
          return this.$t("charge.submitted");

        case "suspect":
          return this.$t("charge.suspect");

        case "rejected":
          return this.$t("charge.rejected");

        case "canceled":
          return this.$t("charge.canceled");

        case "processing":
          return this.$t("charge.processing");

        case "faulted":
          return this.$t("charge.faulted");

        case "done":
          return this.$t("charge.done");

        case "failed":
          return this.$t("charge.failed");

        case "refunded":
          return this.$t("charge.refunded");

        default:
          return "";
      }
    },

    changePage(value) {
      console.log(value);
      this.currentPage = value;
      this.getWithdrawsListFun();
    },

    getFee() {
      getAccounts()
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].code == this.$route.params.walletName) {
                this.fee = new Decimal(res.data.data[i].fee);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    sendMessageFun() {
      const postdata = {
        two_factor_type: "email", // 可选为 'sms' 和 'email'
      };
      sendMessage(postdata)
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            this.message("發送成功", "success");
          } else {
            this.message("發送失敗 請稍候再試", "error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    twoFactorsFun() {
      twoFactors()
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // twoFactorSettingsFun() {
    //   twoFactorSettings()
    //     .then((res) => {
    //       console.log(res.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
.withdrawView {
  height: 100%;

  .title_box {
    @include titleBox;

    .title {
      @include titleBox_title;
    }
  }

  .info_box {
    @include info_box;

    .select_box {
      margin-bottom: 20px;

      .select {
        @include font_color("background_color2");
        font-weight: bold;
        @include background_color("background_color3");
        @include select_background_image("background_color5");
        border: unset;
        font-size: 18px;
        padding: 5px;
        border-radius: 5px;
      }

      select {
        outline: none;
      }
    }
  }

  .input_content {
    width: 75%;
    margin: 0 auto;

    .input_box {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 100%;

      &:first-of-type {
        margin-top: 1rem;
      }

      div {
        width: 100%;
        padding: 12px;
        text-align: left;
      }

      .input {
        margin-bottom: 24px;
        @include formInput;
      }
    }

    .btn {
      @include formBtn;
    }
  }

  .new_input_box {
    @include flex-set();
    margin: 5px 20px;
    padding: 5px;
    justify-content: space-between;
    border-bottom: 1px solid;
    @include border_color("border_color4");

    // width: 100%;
    .title {
      width: 80px;
      text-align: left;
      @include font_color("font_color10");
    }

    .input {
      border: none;
      background: none;
      @include font_color("font_color3");
      font-weight: bold;
      font-size: 16px;
    }

    input {
      outline: none;
    }

    /* Chrome, Safari, Edge, Opera */
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .input_0 {
      width: calc(100% - 160px);
    }

    .input_1 {
      width: calc(100% - 95px);
    }

    .input_2 {
      width: calc(100% - 160px);
    }

    .value {
      width: calc(100% - 160px);
      text-align: right;
      font-weight: bold;
      overflow: hidden;
    }

    .text {
      width: 50px;
      text-align: right;
      @include font_color("font_color10");
    }

    .right {
      text-align: right;
    }

    .btn {
      color: #3584dd !important;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .red_border_bottom {
    border-bottom: 1px solid;
    @include border_color("border_color6");
  }

  .msg {
    font-size: 12px;
    @include font_color("font_color2");
    text-align: center;
    font-weight: bold;
    text-align: right;
    height: 6px;
    margin: 0 20px;
  }
}

.content {
  @include record_wrap;

  .no_data {
    padding: 40px;
    @include font_color("font_color8");
  }

  .list {
    @include record_box;
    padding: 1rem 1.25rem;

    &:first-of-type {
      margin-top: 1rem;
    }

    h1 {
      margin-bottom: 1rem;
    }

    .row {
      @include record;
      margin: 4px 0;

      .break_all {
        word-break: break-all;
        max-width: 70%;
        text-align: right;
      }

      .break_word {
        word-wrap: break-word;
        max-width: 70%;
        text-align: right;
      }
    }
  }

  ::v-deep .el-pagination {
    .btn-prev {
      @include font_color("font_color3");
    }

    .btn-next {
      @include font_color("font_color3");
    }
  }

  ::v-deep .el-loading-mask {
    @include background_color("background_color10");
  }
}
</style>

<style lang="scss">
.el-tabs__item {
  color: #fff !important;
  font-size: 16px !important;
}

.is-active {
  @include font_color("font_color5");
}
</style>
